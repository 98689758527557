import { Row, Col, Image, Button } from "antd";
import experienceflyImg from "../../../assets/experiencefly-img.png";
import centerImg from "../../../assets/centerImg.png";
import iconCircle from "../../../assets/iconCircle.png";
import { FiArrowRightCircle } from "react-icons/fi";

const AboutUsHome = () => {
    return (
        <section className="our-product-section" style={{ overflow: "hidden" }} id="products-section">
            <Row justify={"center"}>
                <Col xxl={18} xl={22} lg={22} xs={23}>
                    <div className="">
                        <Row style={{ padding: "30px 0" }}>
                            <Col lg={24} xs={24}>
                                <h5>LEARn ABOUT JOK</h5>
                                <h2>Our Products</h2>
                            </Col>
                        </Row>
                        <Row gutter={[32]}>
                            <Col lg={17}>
                                <div className="circle-icon-box">
                                    {/* <Image
                                        preview={false}
                                        alt={"Failed to load image"}
                                        src={iconCircle}
                                        style={{ width: "100%" }}
                                        className="rotate"
                                    /> */}
                                    <span className="centerImg-box">
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={centerImg}
                                            style={{ width: "100%" }}
                                        />
                                    </span>
                                    <div className="circle-icon-content-box">
                                        <h4>Your favorite AI tools on your social networks</h4>
                                        <p>Enhance your social interactions with our AI tools for social networks and earn points by completing various quests on our apps. Help JOK become the most influential master of social networks.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="product-right-box coin-video">
                                    <video width="500" autoPlay muted loop>
                                        <source src="https://frontend2.customdev.solutions/jokin-videos/onlyCoin.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>

                                    <h4>Play, Earn Income!</h4>
                                    <p>Play our Telegram app to earn various incomes from our bots, taxes, advertising, and more. Income is distributed regularly based on your points and completed quests.</p>
                                    <Button
                                        style={{
                                            cursor: "pointer",
                                            display: "block",
                                            margin: "28px 0",
                                            height: "48px",
                                            fontSize: "12px"
                                        }}
                                        className="web-btn"
                                    >
                                        <div className="abc">
                                            <span className="btn-text1">
                                                play Now <FiArrowRightCircle />
                                            </span>
                                            <span className="btn-text2">
                                                play Now <FiArrowRightCircle />
                                            </span>
                                        </div>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default AboutUsHome;
