import { Row, Col, Image, Button } from "antd";
import ic1 from "../../../assets/ic1.png";
import ic2 from "../../../assets/ic2.png";
import ic3 from "../../../assets/ic3.png";
import ic4 from "../../../assets/ic4.png";
import ic5 from "../../../assets/ic5.png";
import ic6 from "../../../assets/ic6.png";
import screenShot from "../../../assets/screenShot.png";
import { FiArrowRightCircle } from "react-icons/fi";

const AboutUsHome = () => {
    return (
        <section className="our-product-section service-section" id="services-section">
            <Row justify={"center"}>
                <Col xxl={18} xl={22} lg={22} xs={23}>
                    <div className="">
                        <Row style={{ padding: "30px 0" }}>
                            <Col lg={24} xs={24}>
                                <h5>GROW UP With $JOk</h5>
                                <h2>Our Services</h2>
                            </Col>
                        </Row>
                        <Row gutter={[32]}>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic1}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                        <h6 className="service-name">MEV (Sandwich) BOT</h6>
                                        <p>MEV utilizes the best market strategies to generate
                                            consistent income for our JOKers. We continuously improve it to ensure it remains among the best.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic2}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                    <h6 className="service-name">AI Bot</h6>
                                    <p>We are constantly creating new AI bots integrated
                                        across multiple social platforms to meet your demand and expand our community</p>
                                        </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic3}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                    <h6 className="service-name">Copy Sniping Bot</h6>
                                    <p>Utilize our Sniping copy trading bot on any of the
                                        available blockchains to mirror the best traders and ensure no opportunities are missed.</p>
                                        </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic4}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                    <h6 className="service-name">income Share</h6>
                                    <p>We allocate our income from various bots, taxes,
                                        advertising, shops, and more to the JOKers community.</p>
                                        </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic5}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                    <h6 className="service-name">Deflationary</h6>
                                    <p>JokInTheBox burns tokens bought on the market
                                        using various revenue streams, thereby increasing the value and rarity of the $JOK token.</p>
                                        </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="product-right-box" style={{ height: "auto" }}>
                                    <div className="service-inner-card" style={{ padding: "30px" }}>
                                        <Image
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={ic6}
                                        />
                                    </div>
                                    <div className="service-inner-card-bottom">
                                    <h6 className="service-name">community</h6>
                                    <p>We diligently strive to foster growth and prosperity
                                        within the JOK community through our various quests on the platform, alongside our social bots</p>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default AboutUsHome;
