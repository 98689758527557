import { Typography, Layout, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
    const convertedDate = new Date(date);
    let options = { month: "long", day: "numeric" };
    let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
    return monthAndDate;
};
const UpcomingEvents = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <Layout
            className=""
            style={{ backgroundColor: "#fff", minHeight: "100vh" }}
        >
            <section className="" style={{ background: "#0e0a14", overflow: "hidden" }}>
                <section className="our-product-section service-section privacy-page">
                    <Row justify={"center"}>
                        <Col xxl={16} xl={20} lg={20} xs={23}>
                            <div className="">
                                <Row style={{ padding: "30px 0" }}>
                                    <Col lg={24} xs={24}>
                                        <h2>Terms & Conditions</h2>

                                        <h6>TERMS OF USE</h6>
                                        <p>This agreement, known as the JokInTheBox Services Agreement, establishes a legally binding contract between you and JokInTheBox. It governs your use of JokInTheBox online products and services, collectively referred to as “JokInTheBox Services”. The parties involved in this Agreement are JokInTheBox and the services they provide, including web applications, web applications, Telegram applications, Telegram channels and all applications created and distributed by JokInTheBox, referred to as “JokInTheBox Services”. Users of these services and applications on the web, Telegram and other platforms are called “Users”. By using JokInTheBox Services, you agree to abide by the terms and conditions described in this Agreement, as well as the disclaimers provided herein. You are advised to read this Agreement carefully before engaging in any JokInTheBox Services.</p> <br/><br/>
                                        <p>DISCLAIMER OF WARRANTIES AND REPRESENTATIONS</p>
                                        <br/><br/>
                                        <p>The JokInTheBox Services and all content therein are provided “as is” and “as available” without any warranties or representations of any kind, either express or implied. JokInTheBox acts only as a distributor and not a publisher of content provided by third parties. Therefore, JokInTheBox has no editorial control over this content and makes no warranties or representations as to its accuracy, reliability, or timeliness. This includes any information, content, services or merchandise provided through or accessible through the JokInTheBox Services. More specifically, JokInTheBox disclaims all warranties and representations relating to any content transmitted on or in connection with the JokInTheBox Services or on sites that may appear as links on the JokInTheBox Services, or in the products provided as part of, or otherwise in connection with the JokInTheBox services. This includes all warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights.</p>
                                        <br/><br/>
                                        <p>No advice or information provided by JokInTheBox, its affiliates, employees, officers, directors, agents or similar parties, whether oral or written, will create any warranty. The price and availability of any product or service offered on the JokInTheBox Services are subject to change without notice. Furthermore, JokInTheBox does not guarantee that the JokInTheBox Services will be uninterrupted, intact, timely or error-free.</p>
                                        <br/><br/>
                                        <p>By using the JokInTheBox Services, you agree that JokInTheBox, its affiliates, employees, officers, directors, agents or similar parties, shall not be liable for any damages of any kind arising from or related to your use of the JokInTheBox Services. These damages include, without limitation, direct, indirect, incidental, special, consequential or punitive damages, whether in contract, tort or any other theory of liability, even if JokInTheBox has been advised of the possibility of such damage.</p>
                                        <br/><br/>
                                        <p>LIMITED LIABILITY</p>
                                        <br/><br/>
                                        <p>Use of JokInTheBox services is solely at the user's own risk. JokInTheBox provides materials on its services "as is" and "as available" without any representation or warranty, express or implied, including, but not limited to, the implied warranties of merchantability and fitness for purpose particular. JokInTheBox does not guarantee the accuracy, completeness, reliability, suitability or availability of any information or materials on its services or linked to such information or materials through external sites or channels. The materials and information provided may contain errors or inaccuracies and are subject to change or updating without notice.</p>
                                        <br/><br/>
                                        <p>JokInTheBox shall not be liable for any direct, indirect, incidental, special, consequential or punitive damages arising out of or in any way connected with the use of its services. This includes, without limitation, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of any content (or product) posted, transmitted or otherwise made available via the JokInTheBox service, even if the possibility of such damage has been noted. Such damages may include, but are not limited to, loss of use, lost profits, or loss of data.</p>
                                        <br/><br/>
                                        <p>The user acknowledges and accepts that JokInTheBox is not responsible for the content or services of third party sites or channels. Links to such sites or channels are provided for convenience only and the user accesses them at their own risk. JokInTheBox makes no representations or warranties regarding the accuracy or reliability of any information or materials found on such sites or channels.</p>
                                        <br/><br/>
                                        <p>Except as otherwise required by applicable law, JokInTheBox, its affiliates, officers, directors, employees, agents or licensors shall not be liable for any direct, indirect, incidental, special or consequential damages arising out of or in connection with the use of its services. or materials. The limitations and exclusions of liability set forth herein apply regardless of whether JokInTheBox knew or should have known of the possibility of such damages, and regardless of the form of action, whether in contract, tort or other.</p>
                                        <br/><br/>
                                        <p>By using the JokInTheBox Services, User agrees to release and indemnify JokInTheBox, its affiliates, officers, directors, employees, agents and licensors from any and all claims, demands, damages, liabilities and expenses (including payment fees). 'reasonable lawyer) arising from or in connection with the use of its services.</p>
                                        <br/><br/>
                                        <p>RISKS ASSOCIATED WITH USING OUR SOFTWARE</p>
                                        <br/><br/>
                                        <p>JokInTheBox offers software and services that facilitate returns on cryptocurrency transactions. However, it is important that users understand that certain risks are inherent in the use of such technology. By accessing and using JokInTheBox Services, Users acknowledge and agree that JokInTheBox shall not be liable for any loss, damage or claim arising from events falling into the following categories:</p>
                                        <br/><br/>
                                        <p>User Errors: Users are solely responsible for their actions and should exercise caution and diligence when using JokInTheBox-related software and services. JokInTheBox will not be held responsible for any losses resulting from user errors, including forgotten passwords, payments sent to incorrect JokInTheBox addresses, or accidental deletion of wallets.</p>
                                        <br/><br/>
                                        <p>Software Issues: Despite our efforts to provide reliable and secure software and services, technical problems and software glitches may occur. JokInTheBox will not be liable for any losses resulting from software issues on the website or any software related to JokInTheBox, such as corrupt wallet files, improperly executed transactions, vulnerabilities in cryptographic libraries, or malware affecting the website or the software.</p>
                                        <br/><br/>
                                        <p>Technical Failures: Users are responsible for maintaining the security and integrity of their hardware and storage devices. JokInTheBox will not be responsible for any losses caused by technical failures of user hardware or storage devices, such as data loss due to faulty or damaged devices.</p>
                                        <br/><br/>
                                        <p>Security Breaches: Although JokInTheBox takes measures to protect the security and integrity of its Services, security breaches and unauthorized access may occur. JokInTheBox will not be held responsible for any losses resulting from security breaches encountered by users, such as unauthorized access to wallets or accounts.</p>
                                        <br/><br/>
                                        <p>Third Party Actions or Omissions: JokInTheBox may use third party service providers for certain services, and incidents such as bankruptcy, information security breaches, or fraudulent activities of third parties may occur. JokInTheBox will not be liable for any losses resulting from the actions or omissions of third parties or events involving third parties.</p>
                                        <br/><br/>
                                        <p>By using JokInTheBox services, users acknowledge and accept the inherent risks associated with cryptocurrency transactions. Users release JokInTheBox from any liability related to such risks.</p>
                                        <br/><br/>
                                        <p>COPYRIGHT DISCLAIMER</p>
                                        <br/><br/>
                                        <p>This copyright disclaimer (referred to as the “Disclaimer”) describes the terms and conditions governing the use of material owned by JokInTheBox. All intellectual property rights relating to the material are exclusively owned by JokInTheBox. Copying, distribution or other unauthorized use of the materials without the express written permission of JokInTheBox is strictly prohibited, unless otherwise permitted by mandatory law (such as the right to quote) or expressly provided for specific materials.</p>
                                        <br/><br/>
                                        <p>ERRORS AND OMISSIONS DISCLAIMER</p>
                                        <br/><br/>
                                        <p>This errors and omissions disclaimer (referred to as the “Disclaimer”) sets forth the terms and conditions of use of the service provided by JokInTheBox. We provide no warranties and are not responsible for any inaccuracies, errors or omissions in the Service. In no event will JokInTheBox be liable for any special, direct, indirect, consequential or incidental damages or any damages whatsoever, whether in contract, negligence or other tort, arising out of or in connection with the use of the Service or its content. . We reserve the right to add, delete or modify content on the Service at any time without notice.</p>
                                        <br/><br/>
                                        <p>LINKS TO OTHER WEBSITES/CHANNELS</p>
                                        <br/><br/>
                                        <p>This disclaimer regarding links to other websites/channels (referred to as the “Disclaimer”) applies to the service provided by JokInTheBox. The Service may include links to websites, channels and groups that are not operated or controlled by JokInTheBox. We are not responsible for the content, accuracy, or opinions expressed in such websites, channels, and groups, and their accuracy or completeness is not investigated, monitored, or monitored. verification by JokInTheBox. When you access another website, channel or group through a link on the Service, our Privacy Policy no longer applies. Your browsing and interaction on any other website, including those linked to our platform, is subject to the rules and policies of that website. These third parties may use their own cookies or other methods to collect information about you. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy and terms of each site you visit. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party sites or services.</p>
                                        <br/><br/>
                                        <p>MODIFICATION OF THE DISCLAIMER</p>
                                        <br/><br/>
                                        <p>JokInTheBox reserves the right to modify, amend or update this disclaimer at any time to accurately reflect our service and policies. Any such changes will be prominently posted on our website, and your continued use of the Service constitutes your acceptance of the updated Disclaimer. If you do not wish to be bound by updated terms, you can delete your account.</p>
                                        <br/><br/>
                                        <p>YOUR CONSENT TO THE DISCLAIMER</p>
                                        <br/><br/>
                                        <p>By accessing or registering an account with JokInTheBox, you acknowledge that you have read, understood and accepted the terms of this disclaimer. You also consent to the collection, use and disclosure of your personal information in accordance with our Privacy Policy, as set forth in this Disclaimer. If you do not agree to any provision of this Disclaimer, you must not access or use our Service.</p>
                                        <br/><br/>
                                        <p>COMPLIANCE WITH TAX OBLIGATIONS</p>
                                        <br/><br/>
                                        <p>JokInTheBox users are solely responsible for determining their tax obligations with respect to any transactions made through JokInTheBox. The owners and contributors of JokInTheBox cannot be held responsible for determining any tax implications or liabilities associated with the use of JokInTheBox's services.</p>
                                        <br/><br/>
                                        <p>ARBITRATION</p>
                                        <br/><br/>
                                        <p>By using JokInTheBox's services, User agrees to resolve any dispute arising out of or relating to JokInTheBox or this disclaimer through binding arbitration, except for disputes arising out of copyright , logos, trademarks, trade names, trade secrets or patents. The arbitration will be conducted under the rules of the American Arbitration Association, and the award rendered by the arbitrator(s) will be binding and final. Each party shall bear its own expenses, costs and attorneys' fees incurred in connection with the arbitration, unless applicable law provides otherwise.</p>
                                        <br/><br/>

                                        
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>
            </section>
        </Layout >
    );
};

export default UpcomingEvents;
