import React, { useRef, useState } from 'react';
import { Row, Col, Image, Tabs, Button, Switch } from "antd";
import tabImg1 from "../../../assets/tabImg1.png";
import tabImg2 from "../../../assets/tabImg2.png";
import tabImg3 from "../../../assets/tabImg3.png";
import tabImg4 from "../../../assets/tabImg4.png";
import { FiArrowRightCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';



const AboutUsHome = () => {
    const [source, setSource] = useState('https://frontend2.customdev.solutions/jokin-videos/sandwichVideo.mp4')
    const videoRef = useRef(null);
    const handleTabChange = (key) => {
        setActiveKey(key);
        switch (key) {
            case "1":
                setSource(
                    "https://frontend2.customdev.solutions/jokin-videos/sandwichVideo.mp4"
                );
                break;
            case "2":
                setSource("https://frontend2.customdev.solutions/jokin-videos/video2.mp4");
                break;
            case "3":
                setSource(
                    "https://frontend2.customdev.solutions/jokin-videos/video3.mp4"
                );
                break;
            case "4":
                setSource(
                    "https://frontend2.customdev.solutions/jokin-videos/video4.mp4"
                );
                break;
            default:
                setSource(
                    "https://frontend2.customdev.solutions/jokin-videos/sandwichVideo.mp4"
                );
                break;
        }
        if (videoRef.current) {
            videoRef.current.load();
        }
    };

    const items = [
        {
            key: '1',
            label: <>
                <div className='custum-tab'>
                    <div>
                        <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={tabImg1}
                        // width={20}
                        />
                    </div>
                    <div>
                        <span>The best meal of the JOKers!</span>
                        <h6>Jok MEV Sandwich</h6>
                    </div>
                </div>
            </>,
            children: <>
                <div className="product-right-box">
                    <video width="300" ref={videoRef} autoPlay muted loop>
                        <source src={source} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <h4 style={{ paddingTop: "30px" }}>Jok MEV Sandwich</h4>
                    <p>Our MEV Bot is designed to be one of the best on the market. Our developers and mathematicians continue to improve it for optimal performance. It works on many DEXs and we will deploy it on multiple networks.</p>
                    <Button
                        style={{
                            cursor: "pointer",
                            display: "block",
                            margin: "28px 0",
                            height: "48px",
                            fontSize: "12px"
                        }}
                        className="web-btn"
                    >
                        <Link to={"https://etherscan.io/address/jokinthebox.eth"}>
                            <div className="abc">
                                <span className="btn-text1">
                                    JOk MEV Sandwich
                                </span>
                                <span className="btn-text2">
                                    JOk MEV Sandwich
                                </span>
                            </div>
                        </Link>
                    </Button>
                    <p>The best meal of the JOKers</p>
                </div>
            </>,
        },
        {
            key: '2',
            label: <>
                <div className='custum-tab'>
                    <div>
                        <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={tabImg2}
                        // width={20}
                        />
                    </div>
                    <div>
                        <span>Copy the best JOKers!</span>
                        <h6>Jok Mimic Sniping</h6>
                    </div>
                </div>
            </>,
            children: <>
                <div className="product-right-box">
                    <video width="300" ref={videoRef} autoPlay muted loop>
                        <source src={source} type="video/mp4" />
                    </video>

                    <h4 style={{ paddingTop: "30px" }}>Jok Mimic</h4>
                    <p>Jok Mimic is a bot designed to replicate and snipe trades from top traders or your friends on decentralized exchanges (DEX) or pre-sale platforms. It currently operates on the Ethereum and Binance Smart Chain networks.</p>
                    <Button
                        style={{
                            cursor: "pointer",
                            display: "block",
                            margin: "28px 0",
                            height: "48px",
                            fontSize: "12px"
                        }}
                        className="web-btn"
                    >
                        <Link to={"https://t.me/JokMimicBot"}>
                            <div className="abc">
                                <span className="btn-text1">
                                    JOk MEV Sandwich
                                </span>
                                <span className="btn-text2">
                                    JOk MEV Sandwich
                                </span>
                            </div>
                        </Link>
                    </Button>
                    <p>Copy the best JOKers</p>
                </div>
            </>,
        },
        {
            key: '3',
            label: <>
                <div className='custum-tab'>
                    <div>
                        <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={tabImg3}
                        // width={20}
                        />
                    </div>
                    <div>
                        <span>Tweet with a Twist!</span>
                        <h6>Jok X</h6>
                    </div>
                </div>
            </>,
            children: <>
                <div className="product-right-box">
                    <video width="300" autoPlay muted loop ref={videoRef}>
                        <source src={source} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <h4 style={{ paddingTop: "30px" }}>Jok X</h4>
                    <p>Use Bot X to participate in enriching experiences through our daily quests. Our bot is designed to increase community engagement and increase JOK's visibility through automated tweets.</p>
                    <Button
                        style={{
                            cursor: "pointer",
                            display: "block",
                            margin: "28px 0",
                            height: "48px",
                            fontSize: "12px"
                        }}
                        className="web-btn"
                    >
                        <Link to={"https://twitter.com/JokTimeToTroll"}>
                            <div className="abc">
                                <span className="btn-text1">
                                    JOk X
                                </span>
                                <span className="btn-text2">
                                    JOk X
                                </span>
                            </div>
                        </Link>
                    </Button>
                    <p>Tweet with a Twist! Go Viral, and Profit with Quests.</p>
                </div>
            </>,
        },
        {
            key: '4',
            label: <>
                <div className='custum-tab'>
                    <div>
                        <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={tabImg4}
                        // width={20}
                        />
                    </div>
                    <div>
                        <span>Energize Engagement!</span>
                        <h6>Jok Telegram</h6>
                    </div>
                </div>
            </>,
            children: <>
                <div className="product-right-box">
                    <video width="300" ref={videoRef} autoPlay muted loop>
                        <source src={source} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <h4 style={{ paddingTop: "30px" }}>Jok Telegram</h4>
                    <p>Enhance the atmosphere of your Telegram group by adding the Jok Telegram Bot as a member. Take advantage of its versatile features to reply to top comments and troll those who deserve it.</p>
                    <Button
                        style={{
                            cursor: "pointer",
                            display: "block",
                            margin: "28px 0",
                            height: "48px",
                            fontSize: "12px"
                        }}
                        className="web-btn"
                    >
                        <Link to={"https://t.me/Jok_AI_Bot"}>
                            <div className="abc">
                                <span className="btn-text1">
                                    JOk Telegram
                                </span>
                                <span className="btn-text2">
                                    JOk Telegram
                                </span>
                            </div>
                        </Link>
                    </Button>
                    <p>Energize Engagement on your channel with JOK AI! </p>
                </div>
            </>,
        },
    ];
    const [activeKey, setActiveKey] = useState('1');

    // Reload the video



    const [mode, setMode] = useState('left');
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    const activeTabContent = items.find((item) => item.key === activeKey)?.children;
    return (
        <section className="our-product-section">
            <Row justify={"center"}>
                <Col xxl={18} xl={22} lg={22}>
                    <div className="" style={{ padding: "30px 0" }}>
                        <Row justify={"center"} gutter={[32]}>
                            <Col xl={7} lg={20} md={20}>
                                <div className="product-right-box">
                                    <Tabs
                                        defaultActiveKey="1"
                                        tabPosition={mode}
                                        onChange={handleTabChange}
                                        items={items.map(({ key, label }) => ({ key, label }))}
                                    />
                                    <h4 style={{ paddingTop: "20px" }}>Much more is coming</h4>
                                    <p>We are constantly improving and building new Bots to improve the usefulness and power of $JOK</p>
                                </div>
                            </Col>
                            <Col xl={17} lg={20} md={20}>
                                <div className="product-left-box" style={{ paddingTop: "20px" }}>
                                    <div>
                                        <>{activeTabContent}</>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* <div className='for-flex-box'>
                            <div className="product-right-box">
                                <Tabs
                                    defaultActiveKey="1"
                                    tabPosition={mode}
                                    onChange={handleTabChange}
                                    items={items.map(({ key, label }) => ({ key, label }))}
                                />
                                <h4>Much more is coming</h4>
                                <p>We are constantly improving and building new Bots to improve the usefulness and power of $JOK</p>
                            </div>

                            <div className="product-left-box">
                                <div>
                                    <>{activeTabContent}</>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default AboutUsHome;
