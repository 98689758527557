import { Row, Col, Image, Button } from "antd";
import snap from "../../../assets/snap.png";
import experienceflyImg2 from "../../../assets/experiencefly-img2.png";
import screenShot from "../../../assets/screenShot.png";
import { FiArrowRightCircle } from "react-icons/fi";

const AboutUsHome = () => {
    return (
        <section className="our-product-section" style={{overflow:"hidden"}} id="rewards-section">
            <Row justify={"center"}>
                <Col xxl={18} xl={22} lg={22} xs={23}>
                    <div className="">
                        <Row style={{ padding: "30px 0" }}>
                            <Col lg={24} xs={24}>
                                <h5>EARN WITH $Jok</h5>
                                <h2>Rewards</h2>
                            </Col>
                        </Row>
                        <Row gutter={[32]}>
                            <Col lg={24}>
                                <div className="product-right-box reward-box">
                                    <Row align={"middle"} justify={"center"}>
                                        <Col lg={16} xs={23}>
                                            <h4>Regular Airdrop</h4>
                                            <p>The rewards given to our users will be regular and will not affect the price of $JOK, as they will be in Ethereum, TON, or other partner cryptocurrencies. The income comes from our various bots (MEV, Copy Trading, Sniper), as well as taxes on volume and advertising revenues.</p>
                                            <Button
                                                style={{
                                                    cursor: "pointer",
                                                    display: "block",
                                                    margin: "28px 0",
                                                    height: "48px",
                                                    fontSize: "12px"
                                                }}
                                                className="web-btn"
                                            >
                                                <div className="abc">
                                                    <span className="btn-text1">
                                                        Earn Now <FiArrowRightCircle />
                                                    </span>
                                                    <span className="btn-text2">
                                                        Earn Now <FiArrowRightCircle />
                                                    </span>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col lg={8} xs={23}>
                                            <video width="300" autoPlay muted loop>
                                                <source src="https://frontend2.customdev.solutions/jokin-videos/rewardvideo1.mp4" type="video/mp4" />
                                            </video>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={24}>
                                <div className="product-right-box reward-box reward-box2">
                                    <Row align={"middle"} justify={"center"}>
                                        <Col lg={8} xs={23}>
                                            <video width="300" autoPlay muted loop>
                                                <source src="https://frontend2.customdev.solutions/jokin-videos/rewardVideo2.mp4" type="video/mp4" />
                                            </video>
                                        </Col>
                                        <Col lg={16} xs={23} className="reward-box2-text-right">
                                            <h4>Invite Your Friends</h4>
                                            <p>Share your link from the Telegram app to invite your friends. This will allow you to earn more rewards, gain extra points, and unlock certain features or skills. It will also help make Jok go viral and grow our amazing community of JOKers.</p>
                                            <Button
                                                style={{
                                                    cursor: "pointer",
                                                    display: "block",
                                                    margin: "28px 0",
                                                    height: "48px",
                                                    fontSize: "12px"
                                                }}
                                                className="web-btn"
                                            >
                                                <div className="abc">
                                                    <span className="btn-text1">
                                                        Invite Friends<FiArrowRightCircle />
                                                    </span>
                                                    <span className="btn-text2">
                                                        Invite Friends<FiArrowRightCircle />
                                                    </span>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default AboutUsHome;
