import { Layout, Modal, message } from "antd";
import ClientHeader from "./ClientHeader";
import ClientFooter from "./ClientFooter";

const ClientLayout = ({ children, head, footer = true,bg=true }) => {
  return (
    <Layout
      style={{
        backgroundColor: "#0e0a14",
        scrollBehavior: "smooth",
        position: "relative",
      }}
    >
      <ClientHeader />
      <span className={bg ? "footer-bg" : ""}>
        {children}
        {footer && <ClientFooter />}
      </span>
    </Layout>
  );
};
export default ClientLayout;
