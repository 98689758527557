import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import Homepage from "../../views/homepage";
import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";

const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              bg={false}
              footer={false}
            >
              <Homepage />
            </ClientLayout>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PrivacyPolicy />
            </ClientLayout>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <TermsConditions />
            </ClientLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
