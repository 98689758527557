import React from "react";
import { Row, Col, Typography, Button, Image } from "antd";
import appstore from "../../../assets/app-store.png";
import googleplay from "../../../assets/google-play.png";
import chinesSvg from "../../../assets/chinesSvg.svg";
import { FiArrowRightCircle } from "react-icons/fi";
import { LuTwitter } from "react-icons/lu";
import { FaRegPaperPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
// import 'antd/dist/antd.css';

const { Title } = Typography;

const Topbanner = () => {
  return (
    <Row justify={"center"}>
      <Col xxl={18} xl={22} lg={22} xs={23}>
        <div className="banner-section">
          <Row align={"middle"}>
            <Col lg={13}>
              <div className="first-section-text">
                <h2>Play-to-Earn: Telegram Apps MEV, Copy & AI Social Bot</h2>
                <p>Earn regular income in ETH, TON, and more by playing our game on Telegram. Use our various bots to maximize your profits and become a true JOKER!</p>
                <Button
                  style={{
                    cursor: "pointer",
                    display: "block",
                    margin: "28px 0"
                  }}
                  className="web-btn"
                >
                  <div className="abc">
                    <span className="btn-text1">
                      play & earn crypto now <FiArrowRightCircle />
                    </span>
                    <span className="btn-text2">
                      play & earn crypto now <FiArrowRightCircle />
                    </span>
                  </div>
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    display: "block",
                    margin: "28px 0"
                  }}
                  className="web-btn jok-btn"
                >
                  <Link to={"https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xa728aa2de568766e2fa4544ec7a77f79c0bf9f97&chain=mainnet"}>
                    <div className="abc">
                      <span className="btn-text1">
                        buy & jok <FiArrowRightCircle />
                      </span>
                      <span className="btn-text2">
                        buy & jok <FiArrowRightCircle />
                      </span>
                    </div>
                  </Link>
                </Button>
              </div>
            </Col>
            <Col lg={11}>
              <div className="first-section-video">
                <video width="500" autoPlay muted loop>
                  <source src="https://frontend2.customdev.solutions/jokin-videos/bannerVideo.webm" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <h6 className="baner-h6">Stay informed on our social networks :</h6>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={24}>
              <Button
                style={{
                  cursor: "pointer",
                  margin: "28px 0"
                }}
                className="web-btn-light"
              >
                <Link to={"https://t.me/JokInTheBoxOfficial"}>
                  <div className="abc">
                    <span className="btn-text1">
                      <FaRegPaperPlane />Our Telegram
                    </span>
                    <span className="btn-text2">
                      <FaRegPaperPlane />Our Telegram
                    </span>
                  </div>
                </Link>
              </Button>
              <Button
                style={{
                  cursor: "pointer",
                  margin: "20px 0px 10px 20px"
                }}
                className="web-btn-light"
              >
                <Link to={"https://twitter.com/JokInTheBoxETH"}>
                  <div className="abc">
                    <span className="btn-text1">
                      <LuTwitter />  Our Twitter
                    </span>
                    <span className="btn-text2">
                      <LuTwitter />  Our Twitter
                    </span>
                  </div>
                </Link>
              </Button>
            </Col>
            <Col lg={12} xs={24} className="audit-btn">
              <Button
                style={{
                  cursor: "pointer",
                  margin: "28px 0"
                }}
                className="web-btn-light"
              >
                <Link to={"https://52235e97-40d3-4c5c-b2af-5159000d482c.filesusr.com/ugd/e6b5de_fc3ec8dbb7d6470abc61caee1961bb3d.pdf"}>
                  <div className="abc">
                    <span className="btn-text1">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={chinesSvg}
                        width={20}
                      />Audited and Verified by Assure Defi <FiArrowRightCircle />
                    </span>
                    <span className="btn-text2" style={{ width: "100%", justifyContent: "space-between" }}>
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={chinesSvg}
                        width={20}
                      />READ MORE<FiArrowRightCircle />
                    </span>
                  </div>
                </Link>
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Topbanner;
