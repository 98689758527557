import { Typography, Layout, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
    const convertedDate = new Date(date);
    let options = { month: "long", day: "numeric" };
    let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
    return monthAndDate;
};
const UpcomingEvents = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <Layout
            className=""
            style={{ backgroundColor: "#fff", minHeight: "100vh" }}
        >
            <section className="" style={{ background: "#0e0a14", overflow: "hidden" }}>
                <section className="our-product-section service-section privacy-page">
                    <Row justify={"center"}>
                        <Col xxl={16} xl={20} lg={20} xs={23}>
                            <div className="">
                                <Row style={{ padding: "30px 0" }}>
                                    <Col lg={24} xs={24}>
                                        <h2>Privacy Policy</h2>

                                        <h6>1. Information Collection and Use</h6>
                                        <p><strong>a. Personal Information:</strong> We may collect certain personal information, such as your name, email address, contact number, and other relevant information when you voluntarily provide it to us through forms or interactions on our Website. This information is used to provide you with the services you have requested and to communicate with you regarding our products and services.</p>
                                        <p><strong>b. Financial Information:</strong> In order to provide you with financial services or process financial transactions, we may collect sensitive financial information such as bank account details, credit card information, or investment preferences. We use this information solely for the purpose of fulfilling your requests and delivering our financial services.</p>
                                        <p><strong>c. Log Data:</strong> When you visit our Website, our servers automatically collect certain information, such as your IP address, browser type, referring/exit pages, and operating system. This information helps us analyze trends, administer the Website, and improve user experience.</p>

                                        <h6>2. Cookies and Similar Technologies</h6>
                                        <p>We use cookies and similar technologies to enhance your experience on our Website. Cookies are small files that are stored on your device when you visit our Website. These technologies allow us to recognize your browser and remember certain information, such as your preferences and actions on the Website. You can control cookies through your browser settings, but please note that disabling cookies may affect some features and functionality of our Website.</p>

                                        <h6>3. Information Sharing and Disclosure</h6>
                                        <p><strong>a. Third-Party Service Providers:</strong> We may engage third-party service providers to assist us in delivering our services and improving our Website. These providers may have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                                        <p><strong>b. Legal Compliance:</strong> We may disclose your personal information if required to do so by law or in response to a valid legal request.</p>
                                        <p><strong>c. Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email and/or a prominent notice on our Website of any such change in ownership or control of your personal information.</p>

                                        <h6>4. Security</h6>
                                        <p>We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or electronic storage method is completely secure, and we cannot guarantee absolute security.</p>

                                        <h6>5. Links to External Sites</h6>
                                        <p>Our Website may contain links to external sites operated by third parties. We are not responsible for the content or privacy practices of these external sites. We encourage you to review the privacy policies of these sites before providing any personal information.</p>

                                        <h6>6. Children's Privacy</h6>
                                        <p>Our Website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have unintentionally collected information from a child under 13, please contact us immediately, and we will take appropriate steps to delete the information.</p>

                                        <h6>7. Changes to this Privacy Policy</h6>
                                        <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this policy, we will notify you through a prominent notice on our Website or via email. Please review this Privacy Policy periodically for any updates.</p>

                                        <h6>8. Contact Us</h6>
                                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <a href="mailto:team@jokinthebox.com">team@jokinthebox.com</a>.</p>

                                        <p class="contact">By continuing to use our Website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>
            </section>
        </Layout >
    );
};

export default UpcomingEvents;
