import { useState } from "react";
import { Image } from "antd";
import { MdMenu } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Menu,
  Button,
  Badge,
  Modal,
  Drawer,
  Popover,
  Dropdown,
  Avatar,
  Typography,
  Input,
  Alert,
  message,
} from "antd";
import { FaBars, FaEllipsisV, FaUser, FaSignOutAlt, FaRegPaperPlane } from "react-icons/fa";
import { AiOutlineHeart } from "react-icons/ai";
import { removeUser } from "../../redux/slice/authSlice";
import { IoIosChatbubbles } from "react-icons/io";
import { UPLOADS_URL, AUTH } from "../../config/constants/api";
import { GoBellFill } from "react-icons/go";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { AiFillCaretDown, AiFillApple } from "react-icons/ai";
import Logo from "../../assets/logo-header.png";
import avatar from "../../assets/avatar.png";
import { FiArrowRightCircle } from "react-icons/fi";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { LuTwitter } from "react-icons/lu";
import { Link } from "react-router-dom";

// import Link from 'next/link'

const { Header } = Layout;

const ClientHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);

  const notificationsCount = 10;
  const latestNotifications = [];
  const [logoutModal, setLogoutModal] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <div
          className="headerDropdown"
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            padding: "2px 8px",
          }}
          onClick={() => navigate("/myProfile")}
        >
          My Profile
          {/* <FaUser style={{ fontSize: "14px" }} /> &nbsp; My Profile */}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="headerDropdown"
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            padding: "2px 8px",
          }}
          onClick={() => navigate("/myProfile")}
        >
          Block Service provider
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            padding: "2px 8px",
          }}
          onClick={() => setLogoutModal(true)}
        >
          Logout
        </div>
      ),
    },
  ];

  const content = (
    <div style={{ width: "350px" }}>
      <div
        style={{
          padding: "10px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>Notifications</h3>
        <Alert
          message={`${notificationsCount} New`}
          type="success"
          style={{ fontSize: "12px", padding: "2px 10px", color: "green" }}
        />
      </div>
      <hr
        style={{
          borderLeft: "none",
          borderBottom: "none",
          borderRight: "none",
          borderTop: "1px solid rgb(0 0 0 / 15%)",
        }}
      />
      <div style={{ height: "250px", overflow: "auto" }}>
        {latestNotifications &&
          latestNotifications.length > 0 &&
          latestNotifications.map((item) => {
            return (
              <div
                style={{
                  padding: 10,
                  minHeight: "100px",
                  borderBottom: "1px solid #dadada",
                  marginBottom: "5px",
                }}
              >
                <Row
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Col xs={4}>
                    <div
                      style={{
                        // padding: "10px 10px 10px 10px",

                        display: "flex",
                        width: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40px",
                        backgroundColor: "#385790",
                        borderRadius: "5px",
                      }}
                    >
                      <GoBellFill
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </div>
                  </Col>
                  <Col xs={18}>
                    <Typography.Title
                      className="fontFamily1"
                      style={{ fontSize: "14px", color: "black", margin: 0 }}
                    >
                      {item.title}
                    </Typography.Title>

                    <Typography.Text
                      className="fontFamily1"
                      style={{ fontSize: "12px", color: "black", margin: 0 }}
                    >
                      {item?.content?.slice(0, 100)}{" "}
                      {item.content.length > 100 && "..."}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            );
          })}
      </div>

      <hr
        style={{
          borderLeft: "none",
          borderBottom: "none",
          borderRight: "none",
          borderTop: "1px solid rgb(0 0 0 / 15%)",
        }}
      />

      <div
        style={{
          padding: "10px 20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button onClick={() => navigate("/notifications")} type="link">
          View All
        </Button>
      </div>
    </div>
  );

  const logout = () => {
    setLogoutModal(false);

    dispatch(removeUser());
    navigate("/signin");
  };




  const location = useLocation();

  const handleNavigation = (sectionId) => {
    if (location.pathname === "/") {
      scrollToSection(sectionId);
    } else {
      navigate("/");
      setTimeout(() => scrollToSection(sectionId), 100);
    }
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Header
      style={{
        height: "auto",
        // position: "absolute",
        width: "100%",
        top: 0,
        zIndex: 20,
        // padding: "0px",
        background: "#0e0a14",
        // background: "#fff",
        scrollBehavior: "smooth",
        padding: "30px 0 0 0"
      }}
    >
      <Row
        style={{
          padding: "5px 0",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xxl={18} xl={22} lg={22} xs={24} md={22}>
          <Row className="header-row">
            <Col xs={12} md={12} xl={4} className="">
              <Image
                preview={false}
                alt={"Failed to load image"}
                // width={85}
                // height={85}
                src={Logo}
                style={{ maxWidth: 175 }}
                onClick={() => navigate("/")}
              />
            </Col>
            <Col xs={0} sm={0} xl={16} lg={0}>
              <Menu
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  backgroundColor: "#0e0a14",
                }}
                mode="horizontal"
                className="header-menu hide-on-phone"
              >
                <Menu.Item
                  key="home"
                  className="hover"
                  onClick={() => handleNavigation("products-section")}
                // onClick={() => navigate("/")}
                >
                  Products
                </Menu.Item>
                <Menu.Item
                  key="earlyyears"
                  className="hover"
                  onClick={() => handleNavigation("services-section")}
                // onClick={() => navigate("/aboutUs")}
                >
                  Services
                </Menu.Item>
                <Menu.Item
                  key="dynamiccontinuity"
                  className="hover"
                  onClick={() => handleNavigation("rewards-section")}
                // onClick={() => navigate("/blog")}
                >
                  Rewards
                </Menu.Item>
                <Menu.Item
                  key="serviceblog"
                  className="hover"
                // onClick={() => navigate("/serviceblog")}
                >
                  <Link to={"https://docs.jokinthebox.com/jokinthedocs"}>
                    Docs <BsArrowUpRightCircle style={{ fontSize: "20px" }} />
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="products"
                  className="hover navbtn"
                  onClick={() => navigate("/upcomingEvents")}
                >
                  Enter App <FiArrowRightCircle style={{ fontSize: "20px" }} />
                </Menu.Item>
              </Menu>
            </Col>
            <Col xs={0} lg={0} xl={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="header-find-box"
              >

                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-btn icons-btn"
                >
                  <Link className="abc">
                    <span className="btn-text1">
                      <LuTwitter />
                    </span>
                    <span className="btn-text2">
                      <LuTwitter />
                    </span>
                  </Link>
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-btn icons-btn"
                >
                  <Link className="abc">
                    <span className="btn-text1">
                      <FaRegPaperPlane />
                    </span>
                    <span className="btn-text2">
                      <FaRegPaperPlane />
                    </span>
                  </Link>
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-btn icons-btn"
                >
                  <Link className="abc">
                    <span className="btn-text1">
                      <TfiEmail />
                    </span>
                    <span className="btn-text2">
                      <TfiEmail />
                    </span>
                  </Link>
                </Button>

                {/* <Link>
                  <LuTwitter />
                </Link>
                <Link>
                  <FaRegPaperPlane />
                </Link>
                <Link>
                  <TfiEmail />
                </Link> */}


                {/* {!token ? (
                  <>
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/privateserviceprovider")}
                      className="web-btn-light"
                    >
                      List Your Property
                    </Button>
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/login")}
                      className="web-btn"
                    >
                      Login / Sign Up
                    </Button>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      gap: "5%",
                    }}
                    className="header-btn-container"
                  >
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/privateserviceprovider")}
                      className="web-btn-light"
                    >
                      List Your Property
                    </Button>
                    <Badge
                      count={notificationsCount}
                      style={{
                        backgroundColor: "red",
                        padding: "0",
                        minWidth: "15px",
                        height: "15px",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IoIosChatbubbles
                        style={{ fontSize: "25px", color: "#000" }}
                        onClick={() => navigate("/chat")}
                      />
                    </Badge>
                    <Popover
                      content={content}
                      placement="bottomRight"
                      arrow={false}
                      className="headerPopover"
                    >
                      <Badge
                        count={notificationsCount}
                        style={{
                          backgroundColor: "red",
                          padding: "0",
                          minWidth: "15px",
                          height: "15px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <GoBellFill
                          style={{ fontSize: "25px", color: "#000" }}
                        />
                      </Badge>
                    </Popover>
                    &emsp;
                    <div
                      style={{
                        // minWidth: "200px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        size={30}
                        src={
                          !user.image ? avatar : UPLOADS_URL + "/" + user.image
                        }
                      />

                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <p
                          style={{
                            marginLeft: 10,
                            fontSize: "16px",
                            textTransform: "capitalize",
                            color: "#000",
                          }}
                        >
                          {user?.firstName} <AiFillCaretDown fontSize={12} />{" "}
                        </p>
                      </Dropdown>
                    </div>
                  </div>
                )} */}
              </div>
            </Col>

            <Col xs={12} lg={12} xl={0} style={{ textAlign: "end" }}>
              <MdMenu
                style={{ fontSize: 26, color: "#fff" }}
                onClick={() => setVisible(true)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        className="drawer"
        placement={"left"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        style={{ backgroundColor: "#2e2e68" }}
        key={"drawer"}
      >
        <ImCross
          onClick={() => setVisible(false)}
          style={{
            fontSize: "18px",
            color: "#000",
            display: "block",
            cursor: "pointer",
            marginBottom: "14px",
          }}
        />
        <Image
          preview={false}
          alt={"Failed to load image"}
          // width={100}
          // height={100}
          src={Logo}
          style={{ maxWidth: 175 }}
        />
        <br />
        <br />
        <br />
        <Button
          style={{
            cursor: "pointer",
          }}
          className="web-btn icons-btn"
        >
          <Link className="abc">
            <span className="btn-text1">
              <LuTwitter />
            </span>
            <span className="btn-text2">
              <LuTwitter />
            </span>
          </Link>
        </Button>
        <Button
          style={{
            cursor: "pointer",
          }}
          className="web-btn icons-btn"
        >
          <Link className="abc">
            <span className="btn-text1">
              <FaRegPaperPlane />
            </span>
            <span className="btn-text2">
              <FaRegPaperPlane />
            </span>
          </Link>
        </Button>
        <Button
          style={{
            cursor: "pointer",
          }}
          className="web-btn icons-btn"
        >
          <Link className="abc">
            <span className="btn-text1">
              <TfiEmail />
            </span>
            <span className="btn-text2">
              <TfiEmail />
            </span>
          </Link>
        </Button>
        {/* {token ? (
          <span
            onClick={() => navigate("/login")}
            className="hover signin-link"
            key="products"
          >
            Login I Sign Up
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
            className="header-btn-container"
          >
            <Badge
              count={notificationsCount}
              style={{
                backgroundColor: "red",
                padding: "0",
                minWidth: "15px",
                height: "15px",
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoIosChatbubbles
                style={{ fontSize: "25px", color: "#000" }}
                onClick={() => navigate("/chat")}
              />
            </Badge>
         
            <Popover
              content={content}
              placement="bottomRight"
              arrow={false}
              className="headerPopover"
            >
              <Badge
                count={notificationsCount}
                style={{
                  backgroundColor: "red",
                  padding: "0",
                  minWidth: "15px",
                  height: "15px",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoBellFill style={{ fontSize: "25px", color: "#000" }} />
              </Badge>
            </Popover>
            &emsp;
            <div
              style={{
                // minWidth: "200px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                size={40}
                src={
                  !user.image
                    ? "/images/avatar.png"
                    : UPLOADS_URL + "/" + user.image
                }
              />

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <p
                  style={{
                    marginLeft: 10,
                    fontSize: "16px",
                    textTransform: "capitalize",
                    color: "#000",
                  }}
                >
                  {user?.firstName} <AiFillCaretDown fontSize={12} />{" "}
                </p>
              </Dropdown>
            </div>
          </div>
        )} */}
        <Menu
          style={{
            fontSize: 18,
            fontWeight: 500,
            backgroundColor: "#2e2e68",
            color: "#fff",
          }}
          mode="inline"
          className="header-menu-mobile "
        >
          <Menu.Item key="home" className="hover fontFamily1" onClick={() => {
            setVisible(false);
            handleNavigation("products-section");
          }}>
            Products
          </Menu.Item>
          <Menu.Item key="about" className="hover fontFamily1"  onClick={() => {
            setVisible(false);
            handleNavigation("services-section");
          }}>
            Services
          </Menu.Item>
          <Menu.Item key="tutors" className="hover fontFamily1" onClick={() => {
            setVisible(false);
            handleNavigation("rewards-section");
          }}>
            Rewards
          </Menu.Item>
          <Menu.Item key="coaches" className="hover fontFamily1">
            <Link to={"https://docs.jokinthebox.com/jokinthedocs"}>
              Docs
            </Link>
          </Menu.Item>
          <Menu.Item key="coaches" className="hover fontFamily1">
            Earn App
          </Menu.Item>
        </Menu>
        {/* <Button
          style={{
            cursor: "pointer",
            display: "block",
            margin: "28px 0"
          }}
          className="web-btn"
        >
          <div className="abc">
            <span className="btn-text1">
              EARN APP <FiArrowRightCircle />
            </span>
            <span className="btn-text2">
              EARN APP <FiArrowRightCircle />
            </span>
          </div>
        </Button> */}
        <br />
        <br />
      </Drawer>
    </Header>
  );
};

export default ClientHeader;
