import { Col, Layout, Row, Input, Image, Button } from "antd";
import Fotrlogo from "../../assets/fotrlogo.png";
import Facebook from "../../assets/facebook.png";
import Twitter from "../../assets/twitter.png";
import Icn3 from "../../assets/icn3.png";
import Logo from "../../assets/logo-header.png";
import inst from "../../assets/inst.png";
import snap from "../../assets/snap.png";
import footerlogo from "../../assets/footer-logo.png";
import { useNavigate } from "react-router";
import { FiArrowRightCircle } from "react-icons/fi";
import { LuTwitter } from "react-icons/lu";
import { FaRegPaperPlane } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";

const { Footer } = Layout;
const { TextArea } = Input;
const { Search } = Input;

const options = [
  {
    value: "zhejiang",
    label: "Zhejiang",
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
  },
];
const ClientFooter = () => {
  const navigate = useNavigate();

  const ourProdutcimages3 = [Facebook, Twitter, Icn3];
  return (
    <footer className="ant-footer">
      <div className="ant-container-fluid">
        <Row justify="center">
          <Col xxl={18} xl={22} lg={22}>
            <div className="footer-box ant-footer-box">
              <Row justify={"space-between"}>

                <Col lg={6}>
                  <div className="footer-logo-section">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={Logo}
                      className=""
                    />
                    <p className="footer-webp">By using our services, you agree to our Terms of Service and Privacy Policy. While our tools are provided free of charge, please be aware that transactions on some bots are subject to usage fees.</p>
                    <Button
                      style={{
                        cursor: "pointer",
                        display: "block",
                        margin: "28px 0",
                        height: "48px",
                        fontSize: "12px"
                      }}
                      className="web-btn"
                    >
                      <div className="abc">
                        <span className="btn-text1">
                          Buy $ JOK
                        </span>
                        <span className="btn-text2">
                          Buy $ JOK
                        </span>
                      </div>
                    </Button>
                  </div>
                </Col>
                <Col lg={17}>
                  <Row gutter={[16, 16]}>
                    <Col lg={6} xs={24}>
                      <div className="for-footer-nav ant-for-footer-nav">
                        <ul className="brd-right">
                          <h5>Infos</h5>
                          <li>
                            <a href="https://docs.jokinthebox.com/jokinthedocs">Docs</a>
                          </li>
                          <li>
                            <a href="https://etherscan.io/token/0xa728aa2de568766e2fa4544ec7a77f79c0bf9f97">Etherscan</a>
                          </li>
                          <li>
                            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xaa1d422a231278c99fb6b432509653b16f6f0397?t=1713900138249">Dextools</a>
                          </li>
                          <li>
                            <a href="https://dexscreener.com/ethereum/0xA728Aa2De568766E2Fa4544Ec7A77f79c0bf9F97?utm_source=telegram&utm_medium=bobbybuybot">Dexsceener</a>
                          </li>
                          <li>
                            <a href="https://www.coingecko.com/en/coins/jokinthebox">CoinGecko</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} xs={24}>
                      <div className="for-footer-nav ant-for-footer-nav">
                        <ul className="brd-right">
                          <h5>Products</h5>
                          <li>
                            <a href="https://etherscan.io/address/0x8a3c015e80567ba1e6ee5dbeea775e40ef90c008">MEV Bot</a>
                          </li>
                          <li>
                            <a href="https://t.me/JokMimicBot">Mimic Bot</a>
                          </li>
                          <li>
                            <a href="https://twitter.com/JokTimeToTroll">Troll X Bot</a>
                          </li>
                          <li>
                            <a href="https://twitter.com/JokWhySoSerious">Jok X Bot</a>
                          </li>
                          <li>
                            <a href="https://t.me/Jok_AI_Bot">Telegram Bot</a>
                          </li>
                          <li>
                            <a href="https://t.me/Jok_Sandwich_WaiterBot">Waiter Bot</a>
                          </li>
                          <li>
                            <a href="https://t.me/Jok_Banker_Bot">Banker Bot</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} xs={24}>
                      <div className="for-footer-nav ant-for-footer-nav">
                        <ul className="brd-right">
                          <h5>Socials</h5>
                          <li>
                            <a href="https://t.me/JokInTheBoxOfficial">Telegram</a>
                          </li>
                          <li>
                            <a href="https://twitter.com/JokInTheBoxETH">Twitter</a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/@jokinthebox_official">YouTube</a>
                          </li>
                          <li>
                            <a href="https://www.tiktok.com/@jokinthebox_official">tikTok</a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/jokinthebox_official/">Instagram</a>
                          </li>
                          <li>
                            <a href="https://www.facebook.com/people/Jokinthebox/61568206623580/?mibextid=LQQJ4d&rdid=eLU9mqll03rkbXy8&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FgD4MUQxQQuHoa2H5%2F%3Fmibextid%3DLQQJ4d">Facebook</a>
                          </li>
                          <li>
                            <a href="">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} xs={24}>
                      <div className="for-footer-nav ant-for-footer-nav">
                        <ul>
                          <h5>Legal</h5>
                          <li>
                            <a onClick={() => navigate("/privacyPolicy")}>
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a onClick={() => navigate("/termsConditions")}>Terms and Conditions</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row align={"middle"}>
                <Col lg={18} xs={24}>
                  <p className="copyright-p ant-copyright-p">
                    Copyright © 2023 - All right reserved by JokInTheBox
                  </p>
                </Col>
                <Col lg={6} xs={24}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="header-find-box"
                  >
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      className="web-btn icons-btn"
                    >
                      <Link className="abc">
                        <span className="btn-text1">
                          <LuTwitter />
                        </span>
                        <span className="btn-text2">
                          <LuTwitter />
                        </span>
                      </Link>
                    </Button>
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      className="web-btn icons-btn"
                    >
                      <Link className="abc">
                        <span className="btn-text1">
                          <FaRegPaperPlane />
                        </span>
                        <span className="btn-text2">
                          <FaRegPaperPlane />
                        </span>
                      </Link>
                    </Button>
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      className="web-btn icons-btn"
                    >
                      <Link className="abc">
                        <span className="btn-text1">
                          <TfiEmail />
                        </span>
                        <span className="btn-text2">
                          <TfiEmail />
                        </span>
                      </Link>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default ClientFooter;
